import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from './App';
// import * as serviceWorker from './serviceWorker';

Sentry.init({
	dsn:
		'https://1512b12a8c2e4dcd97ed9fe00e3810c1@o317273.ingest.sentry.io/5514423',
	environment: process.env.REACT_APP_app_env,
});

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
