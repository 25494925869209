import React, { useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { Link, navigate } from '@reach/router';
import { StrongPasswordInput, Button, Card } from '../components/ReactRainbow';
import { AppContext } from '../context';
import ErrorBoundary from '../components/ErrorBoundary';

const isPasswordValid = (password) =>
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
		password
	);

const ChangePassword = () => {
	const {
		appState: { authData },
		setAppState,
	} = useContext(AppContext);
	const [newPassword, setNewPassword] = useState('');
	const [loading, setLoading] = useState(false);

	const changePassword = async (event) => {
		event.preventDefault();
		// trackEvent('change_password', 'click');
		setLoading(true);
		try {
			await Auth.completeNewPassword(authData, newPassword, {});
			// we need the full CognitoIdentity Object for the rest of the app, so getting it below.
			const user = await Auth.currentAuthenticatedUser();
			setAppState({ authData: user });
			setLoading(false);
			navigate('/');
		} catch (error) {
			// displayError(error);
			setLoading(false);
		}
	};

	const getPasswordState = () => {
		if (!newPassword.length) {
			return null;
		}
		if (isPasswordValid(newPassword)) {
			return 'strong';
		}
		return 'weak';
	};
	return (
		<ErrorBoundary>
			<div className="container flex justify-center items-center h-screen mx-auto">
				<div className="w-full p-3 sm:w-3/4 md:w/3/5 lg:w-1/2 xl:w-1/2">
					<Card
						icon={
							<img className="w-8" src="/buddy_lettermark_R.svg" alt="logo" />
						}
						title="Change Password | Partner Portal"
					>
						<form className="p-3 sm:p-8 w-full md:w-4/5 lg:w-3/4 xl:w-3/5 mx-auto">
							<div className="text-center mt-4 sm:mt-8">
								Please choose a new password for your account.
							</div>
							<StrongPasswordInput
								className="mt-5"
								placeholder="********"
								minLength={8}
								type="password"
								autoComplete="new-password"
								hideLabel
								name="password"
								value={newPassword}
								required
								passwordState={getPasswordState()}
								bottomHelpText="Your password must be at least 8 characters long, include at least one number, one uppercase letter and one lowercase letter and a special character."
								onChange={({ target: { value } }) => setNewPassword(value)}
							/>
							<Button
								type="submit"
								variant="brand"
								label="Update Password"
								style={{ display: 'block' }}
								className="mt-5 ml-auto"
								disabled={!newPassword.length}
								isLoading={loading}
								onClick={changePassword}
							/>
							<Link to="/" className="link mt-8 text-center block">
								Back
							</Link>
						</form>
					</Card>
				</div>
			</div>
		</ErrorBoundary>
	);
};

export default ChangePassword;
