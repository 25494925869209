import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Modal } from './components/ReactRainbow';
import {
	DATE_OPTIONS,
	INITIAL_VENUE,
	ROWS_PER_PAGE,
	END_POINT_OPTIONS,
	RESPONSE_OPTIONS,
	VERSION_OPTIONS,
} from './models/UI_MODELS';

const localStorage = window?.localStorage;

let defaultBrand = { name: 'buddy', label: 'Buddy', value: { isVenue: false } };
const storedBrand = localStorage.getItem('brand');
if (storedBrand) {
	defaultBrand = JSON.parse(storedBrand);
}

const dashboardReports = ['ordersPlaced', 'salesDollars', 'quoteToOrderRatio'];
const storedReports = localStorage.getItem('dashboardReports');
if (storedReports) {
	// dashboardReports = storedReports.split(',');
}

const dashboardFilterSettings = {
	dateType: DATE_OPTIONS[1],
	dateRange: [new Date()],
	selectedVenue: INITIAL_VENUE,
	version: VERSION_OPTIONS[1],
};
const storedDashboardFilterSettings = localStorage.getItem('dashboardFilterSettings');
if (storedDashboardFilterSettings) {
	// Object.assign(dashboardFilterSettings, JSON.parse(storedDashboardFilterSettings));
}

const logViewSettings = {
	dateType: DATE_OPTIONS[1],
	dateRange: [new Date()],
	rowsPerPage: ROWS_PER_PAGE[0].value,
	endPoint: END_POINT_OPTIONS[0],
	selectedVenue: INITIAL_VENUE,
	selectedResponse: RESPONSE_OPTIONS[0].value,
	version: VERSION_OPTIONS[1],
};

const storedLogViewSettings = localStorage.getItem('logViewSettings');
if (storedLogViewSettings) {
	// Object.assign(logViewSettings, JSON.parse(storedLogViewSettings));
}

const persist = {
	dashboardFilterSettings: JSON.stringify,
	brand: JSON.stringify,
	dashboardReports: (val) => val.join(','),
	logViewSettings: JSON.stringify,
};

export const AppContext = React.createContext({
	appState: { authData: null, parterData: null, testData: false },
	setAppState: () => {},
});

export const AppProvider = ({ children }) => {
	const [appState, setState] = useState({
																	authData: null,
																	testData: false,
																	brand: defaultBrand,
																	dashboardReports,
																	dashboardFilterSettings,
																	logViewSettings,
																});
	const [modalOptions, setModalOptions] = useState({
		isOpen: false,
		children: null,
	});

	const displayError = (errorMsg, showErrorTitle) => {
		const ErrorComponent = (
			<div className={`px-3 ${showErrorTitle ? 'pb-5' : 'py-5'}`}>
				{errorMsg}
			</div>
		);
		setModalOptions((current) => ({
			...current,
			isOpen: true,
			children: ErrorComponent,
			title: showErrorTitle ? 'Error' : null,
		}));
	};

	const update = (updates) => {
		// update the state here
		setState((current) => ({ ...current, ...updates }));

		// we go through all the keys we are suppose to persist to see if we need to do so
		// check: we might be able to speed it up if we filter the keys first... maybe...
		Object.keys(persist).forEach((key) => {
			if (updates[key]) {
				localStorage.setItem(key, persist[key](updates[key]));
			}
		});
	};

		// accepts a string of item's key name, or array of strings.
		const getCustomPreferences = async (items) => {
			try {
				const { authData } = appState;
				if (!authData?.signInUserSession) {
					return Promise.reject(new Error('User must be signed in.'));
				}
				const { attributes } = authData;
				let requestedData;
				if (Array.isArray(items)) {
					requestedData = items.map((itemName) => ({
						[itemName]: attributes[itemName],
					}));
				} else {
					requestedData = attributes[items];
				}
				return requestedData;
			} catch (error) {
				return Promise.reject(error);
			}
		};

		const setCustomPreferences = async (newPreferencesObject) => {
			try {
				const { authData } = appState;
				if (!authData?.signInUserSession) {
					return Promise.reject(new Error('User must be signed in.'));
				}
				await Auth.updateUserAttributes(authData, newPreferencesObject);
				const newAttributes = { ...authData.attributes, ...newPreferencesObject };
				// spread is too shallow here and doesn't copy object methods over.
				const newAuthData = Object.assign(authData, {
					attributes: newAttributes,
				});
				// instead of making a new network call, let's store our new prefs locally.
				update({ authData: newAuthData });
				return { ...newAttributes };
			} catch (error) {
				return Promise.reject(error);
			}
		};

	return (
		<AppContext.Provider
			value={{
				appState,
				setAppState: update,
				displayError,
				getCustomPreferences,
				setCustomPreferences,
			}}
		>
			<Modal
				{...modalOptions}
				onRequestClose={() => setModalOptions({ isOpen: false })}
			/>
			{children}
		</AppContext.Provider>
	);
};
