import React from 'react';
import { ErrorBoundary } from '@sentry/react';
import { Button } from './ReactRainbow';

const Fallback = () => (
	<div className="w-full py-8 px-3 flex flex-col justify-center items-center">
		Uh oh, an error occurred here. Don't worry, our tech team is on it. In the
		meantime, try reloading the page.
		<br />
		If that doesn't fix the issue and you need further assistance, please
		contact us.
		<Button
			onClick={() => window.location.reload()}
			style={{ display: 'block' }}
			className="mx-auto my-4"
		>
			Reload
		</Button>
	</div>
);
const CustomErrorBoundary = ({ children, fallback = Fallback }) => {
	return <ErrorBoundary fallback={fallback}>{children}</ErrorBoundary>;
};

export default CustomErrorBoundary;
