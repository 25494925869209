export const DATE_OPTIONS = [
	{ name: 'lasthour', label: 'Last Hour' },
	{ name: 'today', label: 'Today' },
	{ name: '48hours', label: 'Last 2 Days' },
	{ name: 'lastweek', label: 'Last Week' },
	{ name: 'last30', label: 'Last 30 Days' },
	{ name: 'specificdate', label: 'Specific Date' },
	{ name: 'sincedate', label: 'Since Date' },
	{ name: 'range', label: 'Custom Range' },
];

export const INITIAL_VENUE = { name: 'all', label: 'All Venues', value: 'all' };

export const ROWS_PER_PAGE = [
	{ name: '10', label: '10', value: 10 },
	{ name: '20', label: '20', value: 20 },
	{ name: '50', label: '50', value: 50 },
	{ name: '100', label: '100', value: 100 },
];

export const VERSION_OPTIONS = [
	{ name: 'v1', label: 'v1' },
	{ name: 'v2', label: 'v2' },
];

export const END_POINT_OPTIONS = [
	{ name: 'all', label: 'All Endpoints' },
	{ name: 'order', label: 'Order' },
	{ name: 'quote', label: 'Quote' },
	{ name: 'order/batch', label: 'Order/Batch' },
	{ name: 'quote/batch', label: 'Quote/Batch' },
];

export const RESPONSE_OPTIONS = [
	{
		value: 'all',
		label: 'All',
	},
	{
		value: 'success',
		label: 'success',
	},
	{
		value: 'error',
		label: 'error',
	},
];

export const THEMES = {
	buddy: {
		rainbow: {
			palette: {
				brand: '#dc5648',
				success: '#e2eba8',
				error: '#dc5648',
			},
		},
	},
	ticketbay: {
		rainbow: {
			palette: {
				brand: '#144276',
				success: '#144276',
			},
		},
	},
	ski: {
		rainbow: {
			palette: {
				brand: '#144276',
				success: '#144276',
			},
		},
	},
	uncleroy: {
		rainbow: {
			palette: {
				brand: '#144276',
				success: '#144276',
			},
		},
	},
	wildlife: {
		rainbow: {
			palette: {
				brand: '#25512D',
				success: '#25512D',
			},
		},
	},
};
