import React from 'react';
import Amplify from 'aws-amplify';
import { Router } from '@reach/router';
import loadable from '@loadable/component';
import config from './amplify.config';
import Login from './views/Login';
import ForgotPassword from './views/ForgotPassword';
import ChangePassword from './views/ChangePassword';
import { AppProvider } from './context';
import './public_styles/tailwind.css';
import './public_styles/App.css';

Amplify.configure(config);

const MainApp = loadable(() => import('./views/MainApp'));

function App() {
	return (
		<AppProvider>
			<div className="flex flex-col items-center">
				<Router className="flex flex-col w-full">
					<Login path="/login" />
					<ChangePassword path="/change-password" />
					<ForgotPassword path="/forgot-password" />
					<MainApp path="/*" />
				</Router>
			</div>
		</AppProvider>
	);
}

export default App;
